declare global {
  interface Window {
    plugins?: any;
  }
}

export interface CordovaApplePayPlugin {
  /**
   * This method checks to see if a card is still valid in the
   * user's wallet on the device using Apple Pay. The card is
   * identified by a card id passed in as the input.
   */
  isTokenized: (
    cardId: string,
    success: (isTokenized: boolean) => void,
    error: (e: string) => void
  ) => void;

  /**
   * This method sends a boolean in the success callback to
   * indicate whether or not a device can be setup with Apple Pay
   */
  isAvailable: (
    success: (isAvailable: boolean) => void,
    error: (e: string) => void
  ) => void;

  /**
   * This method pushes the input card and user information into
   * the Apple Pay Wallet. It will start a user interaction
   * activity to confirm or add any missing address information
   */
  addCard: (
    cardData: CardData,
    headers: any,
    requestUrl: string,
    success: () => void,
    error: (e: string) => void
  ) => void;

  /**
   * This method returns an array of objects. Each object in array
   * is associated to the respective card pass added to the Applepay
   * wallet on device. Every object has a property 'token_ref_id'
   * which is the active token for the card added.
   */
  getCardTokens: (
    success: (data: TokenRefs[]) => void,
    error: (e: string) => void
  ) => void;
}

export interface CardData {
  cardId: string | null;
  name: string;
  last4: string;
  card_type: string;
}

export interface TokenRefs {
  token_ref_id: string;
  token_status?: string;
}

export interface ApplePayPlugin {
  /**
   * Get available called to check whether
   * Apple Pay is available based on device
   * and Returns `true` if available else `false`.
   */
  getApplePayAvailable(): Promise<boolean>;
  getApplePayCardTokens(): Promise<TokenRefs[]>;
  addCardToAppleWallet(
    cardData: CardData,
    headers: any,
    requestUrl: string
  ): Promise<string>;
}

export class ApplePayCordovaPlugin implements ApplePayPlugin {
  constructor(private readonly applePayPlugin: CordovaApplePayPlugin) {}

  getApplePayAvailable(): Promise<boolean> {
    return new Promise((resolve) => {
      this.applePayPlugin.isAvailable(
        (type: boolean) => resolve(type),
        () => resolve(false)
      );
    });
  }

  getApplePayCardTokens(): Promise<TokenRefs[]> {
    return new Promise((resolve) => {
      this.applePayPlugin.getCardTokens(
        (type: TokenRefs[]) => resolve(type),
        () => resolve([])
      );
    });
  }

  addCardToAppleWallet(
    cardData: CardData,
    headers: any,
    requestUrl: string
  ): Promise<string> {
    return new Promise<string>((resolve) => {
      this.applePayPlugin.addCard(
        cardData,
        headers,
        requestUrl,
        () => resolve('success'),
        (e) => resolve(e)
      );
    });
  }
}

export class ApplePayCordovaNoopPlugin implements ApplePayPlugin {
  getApplePayAvailable(): Promise<boolean> {
    return Promise.resolve(false);
  }
  getApplePayCardTokens(): Promise<TokenRefs[]> {
    return Promise.resolve([]);
  }
  addCardToAppleWallet(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    cardData: CardData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    headers: any,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    requestUrl: string
  ): Promise<string> {
    return Promise.resolve('success');
  }
}
